unit UMain;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, VCL.TMSFNCHTMLText;

type
  TForm6 = class(TForm)
    TMSFNCPanel1: TTMSFNCPanel;
    TMSFNCHTMLText1: TTMSFNCHTMLText;
    TMSFNCHTMLText2: TTMSFNCHTMLText;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  Form6: TForm6;

implementation

{$R *.dfm}

procedure TForm6.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  TMSFNCHTMLText1 := TTMSFNCHTMLText.Create(Self);
  TMSFNCHTMLText2 := TTMSFNCHTMLText.Create(Self);

  TMSFNCPanel1.BeforeLoadDFMValues;
  TMSFNCHTMLText1.BeforeLoadDFMValues;
  TMSFNCHTMLText2.BeforeLoadDFMValues;
  try
    Name := 'Form6';
    Width := 755;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    TMSFNCPanel1.SetParentComponent(Self);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Left := 0;
    TMSFNCPanel1.Top := 0;
    TMSFNCPanel1.Width := 755;
    TMSFNCPanel1.Height := 49;
    TMSFNCPanel1.Align := alTop;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 0;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Segoe UI';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.Header.Text := 'Kassensysteme und EDV-Service ';
    TMSFNCPanel1.Header.Size := 50.000000000000000000;
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWhite;
    TMSFNCPanel1.Header.Font.Height := -20;
    TMSFNCPanel1.Header.Font.Name := 'Segoe UI';
    TMSFNCPanel1.Header.Font.Style := [fsBold];
    TMSFNCPanel1.Header.Fill.Color := clBlack;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := 4539717;
    TMSFNCPanel1.Footer.Font.Height := -13;
    TMSFNCPanel1.Footer.Font.Name := 'Segoe UI';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.Footer.Fill.Color := 16380654;
    TMSFNCHTMLText1.SetParentComponent(Self);
    TMSFNCHTMLText1.Name := 'TMSFNCHTMLText1';
    TMSFNCHTMLText1.Left := 570;
    TMSFNCHTMLText1.Top := 55;
    TMSFNCHTMLText1.Width := 185;
    TMSFNCHTMLText1.Height := 82;
    TMSFNCHTMLText1.ParentDoubleBuffered := False;
    TMSFNCHTMLText1.Anchors := [akTop,akRight];
    TMSFNCHTMLText1.Color := clSnow;
    TMSFNCHTMLText1.DoubleBuffered := True;
    TMSFNCHTMLText1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCHTMLText1.Font.Color := clWindowText;
    TMSFNCHTMLText1.Font.Height := -11;
    TMSFNCHTMLText1.Font.Name := 'Segoe UI';
    TMSFNCHTMLText1.Font.Style := [];
    TMSFNCHTMLText1.TabOrder := 1;
    TMSFNCHTMLText1.Fill.Kind := gfkNone;
    TMSFNCHTMLText1.Stroke.Kind := gskNone;
    TMSFNCHTMLText1.Stroke.Color := -1;
    TMSFNCHTMLText1.Text := 'Ralf Stammerjohan <br>Dr'#252'enstr 5 <br>47506 Neukirchen-Vluyn<br>+491629652077<br>rst@e-mail.de';
    TMSFNCHTMLText1.WordWrapping := True;
    TMSFNCHTMLText2.SetParentComponent(Self);
    TMSFNCHTMLText2.Name := 'TMSFNCHTMLText2';
    TMSFNCHTMLText2.Left := 8;
    TMSFNCHTMLText2.Top := 192;
    TMSFNCHTMLText2.Width := 239;
    TMSFNCHTMLText2.Height := 22;
    TMSFNCHTMLText2.ParentDoubleBuffered := False;
    TMSFNCHTMLText2.Anchors := [akLeft,akTop,akRight,akBottom];
    TMSFNCHTMLText2.DoubleBuffered := True;
    TMSFNCHTMLText2.Font.Charset := DEFAULT_CHARSET;
    TMSFNCHTMLText2.Font.Color := clWindowText;
    TMSFNCHTMLText2.Font.Height := -16;
    TMSFNCHTMLText2.Font.Name := 'Segoe UI';
    TMSFNCHTMLText2.Font.Style := [];
    TMSFNCHTMLText2.TabOrder := 2;
    TMSFNCHTMLText2.AutoSize := True;
    TMSFNCHTMLText2.Fill.Kind := gfkNone;
    TMSFNCHTMLText2.Stroke.Kind := gskNone;
    TMSFNCHTMLText2.Stroke.Color := -1;
    TMSFNCHTMLText2.Text := 'Diese Webside ist noch im Aufbau';
    TMSFNCHTMLText2.WordWrapping := True;
  finally
    TMSFNCPanel1.AfterLoadDFMValues;
    TMSFNCHTMLText1.AfterLoadDFMValues;
    TMSFNCHTMLText2.AfterLoadDFMValues;
  end;
end;

end.