program WebRst;

uses
  Vcl.Forms,
  WEBLib.Forms,
  UMain in 'UMain.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm6, Form6);
  Application.Run;
end.
